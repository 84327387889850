/* App.css */

.containerhistory {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cardhistory {
    background-color: #ebe8e8; 
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-title-history {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .card-text-history {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .ordered-product-image {
    width: 150px; /* Adjust the width to a smaller size */
    height: auto; /* Maintain aspect ratio */
    max-height: 150px; /* Ensure the height doesn't exceed a certain size */
    object-fit: contain; /* Ensures the image is scaled correctly without distortion */
}

  
  
  
  
  
  
  
  
  /* header.css
  .nav {
    background-color: black;
  }
  
  .txt {
    color: aliceblue;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .lggg {
      margin-left: -37%; 
      justify-content: center;
      display: flex;
    }
  } */
  