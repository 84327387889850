/* Footer Styles */
.custom-footer {
    background-color: black; /* Footer background color */
    color: white;
    padding: 40px 20px; /* Top and bottom padding */
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Footer content layout */
.footer-content {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px; /* Spacing between sections and bottom */
}

/* Individual footer sections */
.footer-section {
    flex: 1;
    min-width: 200px; /* Minimum width for sections */
    margin: 0 20px; /* Spacing between sections */
    margin-bottom: 20px; /* Spacing at the bottom for stacking */
}

/* Footer headings */
.footer-section h4 {
    margin-bottom: 15px;
    font-weight: bold;
}

/* Footer links */
.footer-section a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

/* Hover effects for links */
.footer-section a:hover {
    color: #00c6ff; /* Change link color on hover */
}

/* Social media icons */
.social-icons {
    display: flex;
    gap: 15px; /* Space between icons */
}

.social-icons a {
    color: white;
    font-size: 1.5rem; /* Icon size */
    transition: color 0.3s ease;
}

/* Hover effects for social icons */
.social-icons a:hover {
    color: #00c6ff; /* Change color on hover */
}

/* Bottom copyright section */
.footer-bottom {
    text-align: center;
    padding-top: 10px; /* Spacing for the bottom */
    font-size: 0.9rem; /* Smaller text */
}

/* Policy links styling */
.policy-links ul {
    padding: 0;
    list-style: none; /* Remove bullet points */
}

.policy-links li {
    margin: 5px 0; /* Space between policy links */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack sections vertically on small screens */
        align-items: center; /* Center align content */
    }

    .footer-section {
        margin: 0; /* Remove side margins */
        margin-bottom: 20px; /* Space below each section */
        text-align: center; /* Center align text */
    }

    .footer-bottom {
        padding-top: 20px; /* Increase space above copyright */
    }
    .social-icons {
        margin-left: 15%;
    }
}
