.payment-verified-container-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f4f8;
    font-family: Arial, sans-serif;
  }
  
  .payment-card-custom {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .icon-container-custom {
    background-color: #4caf50;
    color: #fff;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    font-size: 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .right-arrow-icon-custom {
    font-size: 28px;
    font-weight: bold;
    transform: rotate(0deg);
  }
  
  .verified-title-custom {
    color: #333;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .verified-message-custom {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .details-custom {
    text-align: left;
    margin-top: 20px;
  }
  
  .details-custom p {
    color: #555;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .transaction-id-custom strong,
  .order-id-custom strong {
    color: #000;
  }
  .done-button-custom {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .done-button-custom:hover {
    background-color: #45a049;
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  