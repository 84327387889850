.about-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.about-container {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

h3 {
    font-size: 1.5rem;
    color: #555;
    margin-top: 20px;
}

p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .about-container {
        padding: 20px;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.3rem;
    }

    p {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .about-container {
        padding: 15px;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.9rem;
    }
}
