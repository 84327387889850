/* General Container and Alignment */
.dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f5f5dc; /* Beige background color */
}

/* Center Alignment */
.text-center {
    text-align: center;
}

/* Company Logo */
.company-logo {
    width: 150px;
    margin-bottom: 15px;
}


/* Banner Section */
.banner {
    background-color: #007bff;
    color: white;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 30px;
}

/* Kit Section */
.kit-details {
    margin: 40px 0;
    text-align: center;
    padding: 30px;
    background-color: #ffffff; /* Updated background color to white */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.kit-details h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 25px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.kit-product-card {
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px;
}

.kit-product-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.kit-product-card img {
    height: 250px;
    object-fit: cover;
    border-bottom: 3px solid #007bff;
}

.kit-product-card .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #34495e;
    margin-top: 10px;
    text-transform: capitalize;
}

.kit-product-card .card-text {
    font-size: 1rem;
    color: #7f8c8d;
    margin-top: 10px;
    line-height: 1.5;
    height: 150px; /* Fixed height for text area */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 5px; /* Space for scrollbar */
}

.explore-kit-button {
    margin-top: 20px;
    padding: 12px 30px;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.explore-kit-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* MLM Overview Section */
.mlm-overview {
    background: #ffffff; /* Updated background color to white */
    padding: 30px;
    margin: 40px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mlm-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff; /* Original color */
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.mlm-description {
    font-size: 1.25rem;
    color: #004d40; /* Original color */
    margin-bottom: 40px;
    font-style: italic;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

/* Benefit Icons and Content */
.mlm-benefit-icon {
    font-size: 3rem;
    color: #007bff; /* Original color */
    margin-bottom: 15px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.mlm-benefit-icon:hover {
    transform: scale(1.2);
    color: #004d40; /* Original hover color */
}

.mlm-overview h4 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #00796b; /* Original color */
    margin-bottom: 10px;
    text-transform: capitalize;
}

.mlm-overview p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

/* Call-to-Action Button */
.get-started-button {
    margin-top: 30px;
    padding: 12px 30px;
    background: #4caf50;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, transform 0.3s ease;
}

.get-started-button:hover {
    background: #388e3c;
    transform: translateY(-5px);
}

/* Income Structure Section */
.income-structure {
    background: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    margin: 40px 0;
}

.income-level-card h4 {
    color: #007bff;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .mlm-title {
        font-size: 2rem;
    }

    .mlm-description {
        font-size: 1rem;
    }

    .mlm-overview h4 {
        font-size: 1.5rem;
    }

    .mlm-overview p {
        font-size: 0.9rem;
    }

    .kit-product-card {
        margin-bottom: 20px;
    }

    .kit-product-card img {
        height: 200px;
    }

    .kit-details h2 {
        font-size: 2rem;
    }

    .get-started-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

@media (max-width: 576px) {
    .mlm-title {
        font-size: 1.8rem;
    }

    .mlm-description {
        font-size: 0.9rem;
    }

    .mlm-overview h4 {
        font-size: 1.3rem;
    }

    .mlm-overview p {
        font-size: 0.85rem;
    }

    .mlm-benefit-icon {
        font-size: 2.5rem;
    }

    .kit-product-card img {
        height: 135px;
    }

    .kit-details h2 {
        font-size: 1.8rem;
    }

    .kit-product-card .card-title {
        font-size: 1.3rem;
    }

    .kit-product-card .card-text {
        font-size: 0.9rem;
    }

    .get-started-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}