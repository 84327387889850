/* Unique class names for UserPasswordUpdate component */
.user-password-update-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa; /* Gray background */
}

.user-password-update-box {
  background-color: #ffffff; /* White box */
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.user-password-update-logo {
  max-width: 150px;
  margin: 0 auto 1rem;
}

.user-password-update-title {
  font-size: 1.5rem;
  color: #333333; /* Dark text color */
  margin-bottom: 1.5rem;
}

.user-password-update-input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.user-password-update-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.user-password-update-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.user-password-update-button:hover {
  background-color: #0056b3;
}

.user-password-update-footer {
  font-size: 0.875rem;
  color: #333333;
  margin-top: 1.5rem;
  opacity: 0.8;
}
