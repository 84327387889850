/* Disclaimer Alert */
.disclaimer-alert {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    font-size: 14px; /* Adjusted for better balance */
    margin-bottom: 20px;
    text-align: center; /* Ensures text is centered */
}

.disclaimer-icon {
    font-size: 36px; /* Icon size */
    margin-bottom: 8px; /* Adds space below the icon */
}

/* Container */
.unique-pan-update-container {
    max-width: 90%;
    margin: 10px auto;
    padding: 20px;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    text-align: center;
    color: #fff;
    font-family: 'Arial', sans-serif;
}



/* Form */
.unique-pan-update-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.unique-pan-update-form label {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.unique-pan-update-form input {
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.unique-pan-update-form input:focus {
    outline: none;
    border: 2px solid #007bff;
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

/* Button */
.unique-pan-update-button {
    padding: 12px;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.unique-pan-update-button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, #feb47b, #ff7e5f);
}

/* Error Message */
.unique-pan-update-error-message {
    color: #f8d7da;
    background-color: #f5c6cb;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
}

/* Success Message */
.unique-pan-update-success-message {
    color: #d4edda;
    background-color: #c3e6cb;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .unique-pan-update-container {
        padding: 15px;
        font-size: 14px;
    }

    .unique-pan-update-title {
        font-size: 22px;
    }

    .unique-pan-update-form input,
    .unique-pan-update-button {
        font-size: 14px;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .unique-pan-update-container {
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    }

    .unique-pan-update-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .unique-pan-update-form input {
        font-size: 14px;
    }

    .unique-pan-update-button {
        font-size: 14px;
    }
}
