.menu-list {
    list-style-type: none;
    padding: 0;
    margin-left: 0px;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px; /* Adjust this to decrease the space between logo and help */
}

.menu-icon {
    font-size: 40px; /* Adjust the size of the icon */
    margin-top: 5px;
}

.menu-link {
    font-size: 14px; /* Adjust the size of the text */
    text-align: center;
}

.withdrawalsymobl {
    margin-top: -37px;
}
.whatsapp-icon-header {
    color: #25d366;
    width: 50px; /* Set the desired width */
    height: 50px; /* Set the desired height */
    display: inline-block; /* Ensure it behaves like a block to respect width and height */
}


@media (min-width: 320px) and (max-width: 375px) {
    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0px; /* Adjust this to decrease the space between logo and help */
    }
    .withdrawalsymobl {
        margin-top: -46px;
        margin-left: -9px;
    }
    .menu-list {
        list-style-type: none;
        padding: 0;
        margin-left: -8px;
    }
    .whatsapp-icon-header {
        color: #25d366;
        width: 40px; /* Set the desired width */
        height: 40px; /* Set the desired height */
        display: inline-block; /* Ensure it behaves like a block to respect width and height */
    }
}

@media (min-width: 375px) and (max-width: 425px) {
    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px; /* Adjust this to decrease the space between logo and help */
    }
    .withdrawalsymobl {
        margin-top: -50px;
        margin-left: 0px;
    }
    .whatsapp-icon-header {
        color: #25d366;
        width: 50px; /* Set the desired width */
        height: 50px; /* Set the desired height */
        display: inline-block; /* Ensure it behaves like a block to respect width and height */
    }
}

@media (min-width: 425px) and (max-width: 768px) {
    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 30px; /* Adjust this to decrease the space between logo and help */
    }
    .withdrawalsymobl {
        margin-top: -37px;
        margin-left: 15px;
    }
    .whatsapp-icon-header {
        color: #25d366;
        width: 50px; /* Set the desired width */
        height: 50px; /* Set the desired height */
        display: inline-block; /* Ensure it behaves like a block to respect width and height */
    }
}
