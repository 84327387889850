/* src/components/TermsAndConditions.css */

.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.terms-title {
    text-align: center;
    color: #333;
}

.terms-section-title {
    margin-top: 20px;
    color: #0056b3;
}

.terms-text {
    margin: 10px 0;
    color: #555;
}
