
  /* test.css */
.profile-img-logo {
    cursor: pointer;
    margin-top: -50px;
  }
  
  .profile-logo {
    border-radius: 50%;
  }
  
  .profile-card {
    border: none;
  }
  
  .profile-image {
    width: 100px; /* Adjust the width of the profile image */
    height: 100px; /* Adjust the height of the profile image */
  }
  
  .profile-info {
    align-items: center;
  }
  
  .profile-button-group {
    margin-top: 10px;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 576px) {
    .profile-image {
      width: 80px; /* Decrease the width of the profile image for smaller screens */
      height: 80px; /* Decrease the height of the profile image for smaller screens */
    }
  
    .profile-info p {
      font-size: 0.8rem; /* Decrease font size for smaller screens */
    }
    .profile-img-logo {
        cursor: pointer;
      }
      .profile-button-group {
        margin-top: 10px;
        width: 90%;
      }
  }
  
  @media (max-width: 320px) {
    .profile-info p {
      font-size: 0.7rem; /* Further decrease font size for screens smaller than 320px */
    }
  }
  