* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
  padding: 1rem;
}

.glass-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px) saturate(180%);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.glass-card:hover {
  transform: scale(1.03);
  box-shadow: 0 12px 36px rgba(31, 38, 135, 0.35);
}

.login-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  padding-right: 3.5rem;
  font-size: 1rem;
  border-radius: 12px;
  border: none;
  background: rgba(240, 240, 240, 0.7);
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.login-input:focus {
  outline: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.3s;
}

.toggle-password:hover {
  color: #444;
}

.error-text {
  color: #ff6b6b;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

/* Updated Login Button Styles */
.login-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  background: linear-gradient(135deg, #667eea, #764ba2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.login-button:hover {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  transform: translateY(-2px);
}

.login-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.login-link {
  color: #555;
  font-size: 0.9rem;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.login-link:hover {
  color: #333;
}

@media (max-width: 500px) {
  .glass-card {
      padding: 1.5rem;
  }
  .login-title {
      font-size: 1.5rem;
  }
  .login-button {
      font-size: 1rem;
  }
}
