/* Position the button in the bottom right corner */
.email-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  /* Button style */
  .email-button {
    background-color: #0056b3; /* Blue color for email button */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 50px; /* Rounded edges for a modern look */
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 4px 20px rgba(0, 0, 0, 0.1); /* 3D shadow effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    transform: translateY(0); /* Initial position */
  }
  
  .email-button:hover {
    background-color: #003a7f; /* Darker blue on hover */
    transform: translateY(-5px); /* Lift the button up on hover (3D effect) */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 24px rgba(0, 0, 0, 0.1); /* Enhance shadow on hover */
  }
  
  /* Icon style */
  .email-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px; /* Space between icon and text */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .email-button {
      padding: 12px 25px;
      font-size: 16px;
    }
  
    .email-icon {
      width: 22px;
      height: 22px;
    }
  }
  
  @media (max-width: 480px) {
    .email-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  
    .email-icon {
      width: 18px;
      height: 18px;
    }
  }
  