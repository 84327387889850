/* General Styles */
.enhanced-marketing-container {
  margin-top: 50px;
  padding: 20px;
  background: linear-gradient(to bottom, #f4f6f9, #ffffff);
}

/* Header Styles */
.enhanced-marketing-title { 
  font-size: 40px;
  font-weight: bold;
  color: #4caf50;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.enhanced-marketing-subtitle {
  font-size: 18px;
  color: #666;
  margin-top: 10px;
  font-style: italic;
}

/* Card Styles */
.income-structure-card,
.program-details-card,
.graph-card {
  border-radius: 20px;
  background: #ffffff;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.income-structure-card:hover,
.program-details-card:hover,
.graph-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

/* Table Styles */
.enhanced-income-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 0.8s ease-out;
}

.enhanced-income-table th {
  background: #4caf50;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
}

.enhanced-income-table td {
  color: #333;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  transition: background 0.3s ease;
}

.enhanced-income-table tr:nth-child(even) {
  background: #f9f9f9;
}

.enhanced-income-table tr:hover {
  background: #e8f5e9;
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
}

.enhanced-income-table td:first-child {
  font-weight: bold;
  color: #4caf50;
}

/* Program Details */
.program-details-text {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
}

.enhanced-program-list {
  list-style: none;
  padding: 0;
}

/* Shared List Styles */
.unified-list li {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px; /* Consistent gap between icon and text */
}

/* Specific Styles for Program Icon */
.program-icon {
  font-size: 24px;
  color: #4caf50; /* Default icon color */
}

/* Button Styles */
.toggle-language-button {
  margin-top: 20px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.toggle-language-button:hover {
  background: #388e3c;
  transform: scale(1.05);
}


.program-icon {
  font-size: 24px;
  margin-right: 10px; /* Can adjust or remove since gap is added */
  color: #4caf50;
}

/* Toggle Button */
.toggle-language-button {
  margin-top: 20px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.toggle-language-button:hover {
  background: #388e3c;
  transform: scale(1.05);
}

/* Graph Styles */
.graph-card {
  padding: 30px;
  background: linear-gradient(to top, #ffffff, #f8f9fa);
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .enhanced-marketing-title {
    font-size: 32px;
  }

  .enhanced-marketing-subtitle {
    font-size: 16px;
  }

  .program-details-text {
    font-size: 14px;
  }

  .enhanced-program-list li {
    font-size: 14px;
  }

  .toggle-language-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .enhanced-income-table th,
  .enhanced-income-table td {
    font-size: 14px;
    padding: 10px;
  }
}