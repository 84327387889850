.ecommerce-accordion {
    margin: 20px;
}

.accordion-item {
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.accordion-header {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.accordion-header:hover {
    background-color: #0056b3;
}

.accordion-body {
    background-color: #f8f9fa;
    border-top: 1px solid #e0e0e0;
    border-radius: 0 0 5px 5px;
    padding: 15px;
}

.product-name {
    color: #343a40;
    font-weight: bold;
}

.product-mrp,
.product-gst,
.product-discount {
    color: #495057;
}

.total-price {
    color: #007bff;
    font-weight: bold;
}

.address-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
}

.address-input {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    transition: border-color 0.3s;
}

.address-input:focus {
    border-color: #007bff;
    outline: none;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    font-weight: bold;
}

.add-address-btn {
    margin-top: 10px;
}

.submit-btn {
    margin-top: 10px;
}

.disabled-btn-pay-now {
    filter: blur(0.3px);
    opacity: 0.5;
    cursor: not-allowed;
}
