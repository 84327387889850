/* Offer.css */
.offer-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #cce7cc; /* Light green border */
  border-radius: 8px;
  text-align: center;
  font-family: Arial, sans-serif;
  background: #f0f9f0; /* Very light green background */
}

.offer-title {
  font-size: 1.5rem;
  color: #2d572c; /* Dark green for contrast */
  margin-bottom: 10px;
}

.offer-date {
  font-size: 1rem;
  font-weight: bold;
  color: #388e3c; /* Medium green */
  margin-bottom: 10px;
}

.offer-card {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #a5d6a7; /* Softer green border */
  margin: 10px 0;
}

h2 {
  font-size: 1.2rem;
  color: #1b5e20; /* Deep green */
  margin-bottom: 5px;
}

.offer-note {
  font-size: 0.9rem;
  color: #4c8c4a; /* Soft green */
  margin-top: 10px;
}

.toggle-button {
  padding: 8px 12px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 15px;
  background: #007bff;
  color: white;
  transition: 0.3s;
}

.toggle-button:hover {
  background: #0056b3;
}

/* Buy Now Button */
.buy-button {
  display: inline-block;
  padding: 10px 15px;
  background: #ff5722;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  transition: 0.3s;
}

.buy-button:hover {
  background: #e64a19;
}
