/* Google Fonts & FontAwesome */
@import url('https://fonts.googleapis.com/css?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800');
@import 'font-awesome/css/font-awesome.min.css';

/* Wrapper for tree structure */
.tree-container {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 50vh;
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: center;
    background: white;
    font-family: 'Poppins', sans-serif;
    overflow: auto; /* Allow scrolling for small devices */
}

.tree {
    width: 100%;
    max-width: 1000px; /* Limit maximum width for larger devices */
    margin: auto;
    padding: 10px;
    overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
}

/* Tree Structure */
.tree ul {
    padding-top: 20px;
    position: relative;
    transition: 0.5s;
    padding-left: 0; /* Remove default padding */
    display: flex;
    justify-content: center; /* Center children */
    align-items: center;
}

/* Tree Nodes (Links) */
.tree li {
    display: inline-table;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 5px;
    min-width: 120px; /* Ensure a minimum width for better visibility */
}

/* Lines connecting the tree structure */
.tree li:before,
.tree li:after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #060000;
    width: 51%;
    height: 10px;
}

.tree li:after {
    right: auto;
    left: 50%;
    border-left: 1px solid #0b0202;
}

.tree li:only-child:after,
.tree li:only-child:before {
    display: none;
}

/* Customize connectors for first/last child */
.tree li:first-child:before,
.tree li:last-child:after {
    border: 0 none;
}

.tree li:last-child:before {
    border-right: 1px solid #080000;
    border-radius: 0px 5px 0px 0px;
}

.tree li:first-child:after {
    border-radius: 5px 0px 0px 0px;
}

.tree ul ul:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #0a0101;
    width: 0;
    height: 20px;
}

/* Tree Nodes (Links) */
.tree a {
    border: 1px solid rgb(0, 0, 0);
    padding: 10px;
    display: inline-grid;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s;
    text-align: center;
    max-width: 100%; /* Ensure full width scaling for small devices */
}

.tree a img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px !important;
    border-radius: 50%;
}

.tree a span {
    border: 1px solid #060113;
    border-radius: 5px;
    color: #070000;
    padding: 5px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
}

/* Hover Effects */
.tree li a:hover,
.tree li a:hover img,
.tree li a:hover span,
.tree li a:hover+ul li a {
    background: #c8e4f8;
    color: #100101;
    border: 1px solid #94a0b4;
    box-shadow: 0px 0px 8px 5px #5f5f5f;
}

.tree li a:hover+ul li:after,
.tree li a:hover+ul li:before,
.tree li a:hover+ul:before,
.tree li a:hover+ul ul:before {
    border-color: #274475;
}

/* Simple Back Button */
.back-button {
    padding: 12px 24px;
    background-color: #f7c09e; /* Solid color */
    color: #2c1e16; /* Dark text color */
    border: none;
    border-radius: 50px; /* Rounded button */
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    position: fixed; /* Keeps button in a fixed position */
    bottom: 20px; /* Position at the bottom of the screen */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center button precisely */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Simple shadow */
    transition: all 0.3s ease; /* Smooth transition */
    font-family: 'Poppins', sans-serif; /* Elegant font */
}

/* Hover Effects */
.back-button:hover {
    background-color: #f4a38d; /* Slightly darker shade */
    color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3); /* Light shadow */
}

/* Active Effects */
.back-button:active {
    background-color: #e59873; /* Darker shade on click */
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2); /* Inset shadow */
}


/* Responsive Adjustments */

/* Tablets and small desktops (max-width: 768px) */
@media (max-width: 768px) {
    .tree a {
        padding: 8px;
        font-size: 10px;
    }

    .tree a img {
        width: 35px;
        height: 35px;
    }

    .tree a span {
        font-size: 10px;
    }

    .tree li {
        min-width: 80px; /* Adjust minimum width for smaller devices */
    }
}

/* Small devices and phones (max-width: 480px) */
@media (max-width: 480px) {
    .tree {
        padding: 5px;
    }

    .tree a {
        padding: 6px;
        font-size: 9px;
    }

    .tree a img {
        width: 30px;
        height: 30px;
    }

    .tree li {
        min-width: 60px; /* Ensure smaller tree node size */
    }
}

/* Extra small devices (max-width: 320px) */
@media (max-width: 320px) {
    .tree a {
        padding: 5px;
        font-size: 8px;
    }

    .tree a img {
        width: 25px;
        height: 25px;
    }

    .tree li {
        min-width: 50px;
    }
}