

.withdrawal-img-logo {
    cursor: pointer;
}

.withdrawal-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    color: rgb(181, 33, 33); /* Change text color if necessary */
    font-size: 1.2rem; /* Corrected font size */
    font-weight: bold;
    
}

.withdrawal-logo-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 10px; /* Adjust as needed */
    height: 30px;
    margin-left: 100px;
}

.withdrawal-card {
    border: none;
}

.withdrawal-image {
    width: 100px;
    height: 100px;
}

.withdrawal-info {
    align-items: center;
}

.withdrawal-button-group {
    margin-top: 10px;
}

/* Center the card */
.text-center {
    text-align: center;
}

.userbalanceamount{
    font-size: 0.85rem;
}


/* Media queries for responsive design */
@media (max-width: 576px) {
    .withdrawal-image {
        width: 80px;
        height: 80px;
    }

    .withdrawal-info p {
        font-size: 0.8rem;
    }
}

@media (max-width: 320px) {
    .withdrawal-info p {
        font-size: 0.7rem;
    }
}