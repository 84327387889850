/* General Card Styling */
.info-card {
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1), -8px -8px 15px rgba(255, 255, 255, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 15px;
    background: linear-gradient(145deg, #ffffff, #f2f3f5);
    padding: 20px;
    align-items: center;
    margin: 10px auto;
    position: relative;
}

.info-card:hover {
    transform: translateY(-8px);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2), -10px -10px 20px rgba(255, 255, 255, 0.9);
}

.info-card:before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, rgba(0, 123, 255, 0.1), rgba(108, 117, 125, 0.1));
    border-radius: inherit;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.info-card:hover:before {
    opacity: 1;
}

/* Card Text */
.info-card .text-center {
    font-weight: bold;
    font-size: 18px;
    color: #495057;
    z-index: 1;
    position: relative;
}

/* Modal Styling */
.premium-modal .modal-dialog {
    max-width: 400px; /* Adjust the modal width */
    margin: 1.75rem auto; /* Center the modal with margins */
}

.premium-modal .modal-content {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2), -10px -10px 20px rgba(255, 255, 255, 0.8);
    animation: fadeIn 0.3s ease;
    backdrop-filter: blur(8px); /* Blur the background for a modern effect */
    width: 85%;
    margin: auto; /* Center horizontally */
    margin-top: 5%; /* Default margin from the top */
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
    .premium-modal .modal-content {
        width: 85%; /* Slightly narrower for smaller screens */
        margin-top: 5%; /* Increase top margin for smaller screens */
        margin-bottom: 8%; /* Add bottom margin for better spacing */
    }
}

/* Modal Header */
.premium-modal-header {
    background: rgb(213 146 146 / 90%);
    border-radius: 15px 15px 0 0;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
}

.premium-modal-title {
    font-size: 1.4rem;
    color: #343a40;
    font-weight: bold;
}

/* Modal Body */
.premium-modal-body {
    padding: 15px; /* Reduced padding for compact design */
    font-size: 0.95rem;
    color: #6c757d;
    text-align: center;
}

/* Carousel Styling */
.carousel-level-card {
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(145deg, #ffffff, #f1f3f5);
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.8);
    text-align: center;
    transition: transform 0.3s ease;
}

.carousel-level-card img {
    width: 70%; /* Reduce image size */
    border-radius: 10px;
    margin-bottom: 10px;
}

.carousel-level-card:hover {
    transform: scale(1.03);
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.9);
}

/* Upcoming Levels Title */
.premium-upcoming-title {
    font-size: 1.4rem;
    color: #b8860b;
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .info-card {
        padding: 15px;
    }

    .info-card {
        width: 90%; /* Smaller screens ke liye */
    }

    .premium-modal-body {
        font-size: 0.85rem;
    }

    .carousel-level-card {
        padding: 8px;
    }

    .premium-upcoming-title {
        font-size: 1.2rem;
    }
}

/* Animation for Modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}