.withdrawal-history {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.withdrawal-history h2 {
    margin-bottom: 20px;
    color: #333;
}

/* Update Bank Details Button */
.update-bank-details-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.update-bank-details-button {
    padding: 12px 24px; /* Increased padding for a modern look */
    font-size: 18px; /* Slightly larger text for better readability */
    font-weight: bold; /* Bold font for emphasis */
    color: #fff; /* Keep text white for contrast */
    background: linear-gradient(45deg, #ff416c, #ff4b2b, #ff6a00); /* Gradient background */
    border: none; /* No border for a clean design */
    border-radius: 50px; /* Rounded edges for a button-pill style */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow for a floating effect */
    transition: all 0.4s ease; /* Smooth transition for hover effects */
}

.update-bank-details-button:hover {
    background: linear-gradient(45deg, #ff6a00, #ff416c, #ff4b2b); /* Reverse the gradient for hover effect */
    transform: translateY(-3px); /* Slightly move the button up for interaction feedback */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4); /* Enhance shadow for hover */
}



@media (max-width: 768px) {
    .update-bank-details-button {
        width: 100%;
        font-size: 14px;
    }
}

/* Income Summary */
.income-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.income-item {
    padding: 15px;
    background-color: #e9ecef;
    border-radius: 5px;
    text-align: center;
    flex: 1;
    margin: 0 10px;
}

.income-item h3 {
    margin: 0;
    color: #333;
}

/* Month Selector */
.month-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.month-selector label {
    margin-right: 10px;
    color: #333;
    font-size: 14px;
}

.month-selector select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 150px;
    max-width: 100%;
}

/* Withdrawal Table */
.withdrawal-table {
    width: 100%;
    border-collapse: collapse;
}

.withdrawal-table th,
.withdrawal-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.withdrawal-table th {
    background-color: #007bff;
    color: white;
}

.withdrawal-table tr:hover {
    background-color: #f1f1f1;
}

.withdrawal-amount {
    font-weight: bold;
}

.amount-positive {
    color: green;
}

.amount-negative {
    color: red;
}

.withdrawal-status {
    font-weight: bold;
}

.withdrawal-status.pending {
    color: orange;
}

.withdrawal-status.approved {
    color: green;
}

.withdrawal-status.failed {
    color: red;
}

.no-withdrawals {
    text-align: center;
    color: #777;
    font-style: italic;
}

@media (max-width: 768px) {
    .month-selector {
        flex-direction: column;
        align-items: flex-start;
    }

    .month-selector label {
        margin-bottom: 10px;
    }

    .month-selector select {
        width: 100%;
    }
}
